/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';

import ArrowRightSvg from '@stories/atoms/Icons/arrow_right.svg';
import CaretDownSvg from '@stories/atoms/Icons/caret_down_md.svg';

import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';
import type { ReactNode } from 'react';

export const glassArrowCss = {
  backgroundColor: 'hsl(0 0% 100% / 45%)',
  backdropFilter: 'blur(25px)',
  boxShadow: '0 0 3px hsl(0 0% 0% / 50%)',
  transition: 'opacity 250ms ease-in-out, transform 250ms ease-in-out',
  ':disabled': { opacity: 0, transform: 'scale(0.2) rotate(75deg)' }
};

export const defaultArrowButtonSize = 2.75;

const styleCssFn = (t: Theme) => ({
  solid: {
    backgroundColor: t.color.grey.g92,
    color: t.color.primary,
    '@media (hover: hover) and (pointer: fine)': {
      ':hover': {
        backgroundColor: t.color.hover.g92,
        color: t.color.hover.primary
      }
    },
    ':disabled': {
      cursor: 'not-allowed',
      opacity: '0.4',
      pointerEvents: 'none'
    }
  },
  outline: {
    backgroundColor: 'transparent',
    border: `1px solid ${t.color.grey.g92}`,
    color: t.color.grey.g92,
    transition: 'opacity 250ms ease-in-out',
    ':hover': {
      backgroundColor: 'hsl(0 0% 100% / 5%)'
    },
    ':disabled': {
      cursor: 'not-allowed',
      opacity: '0.4',
      pointerEvents: 'none'
    }
  }
});

export type ArrowButtonProps = {
  onClick?: () => void;
  cssProps?: Interpolation<Theme>;
  disabled?: boolean;
  outline?: boolean;
};

const GenericButton: React.FC<ArrowButtonProps & { children: ReactNode }> = ({
  children,
  cssProps,
  disabled,
  onClick,
  outline
}) => {
  const theme = useTheme();
  return (
    <button
      onClick={onClick}
      css={() => ({
        borderRadius: `var(--arrow-button-size, ${defaultArrowButtonSize}rem)`,
        lineHeight: `var(--arrow-button-size, ${defaultArrowButtonSize}rem)`,
        width: `var(--arrow-button-size, ${defaultArrowButtonSize}rem)`,
        ...(styleCssFn(theme)[outline ? 'outline' : 'solid'] as object),
        ...(cssProps as object)
      })}
      disabled={disabled}
      type="button"
    >
      {children}
    </button>
  );
};

export const ArrowButton: React.FC<ArrowButtonProps> = ({ onClick, cssProps, disabled, outline }) => {
  const theme = useTheme();

  return (
    <GenericButton onClick={onClick} cssProps={cssProps} disabled={disabled} outline={outline}>
      <ArrowRightSvg
        css={{
          display: 'block',
          height: `var(--arrow-button-size, ${defaultArrowButtonSize}rem)`,
          margin: 'auto',
          stroke: theme.color.primary
        }}
      />
    </GenericButton>
  );
};

export const CaretDownButton: React.FC<ArrowButtonProps> = ({ onClick, cssProps, disabled, outline }) => {
  const theme = useTheme();

  return (
    <div css={{ '--arrow-button-size': '1.5rem' }}>
      <GenericButton onClick={onClick} cssProps={cssProps} disabled={disabled} outline={outline}>
        <CaretDownSvg
          css={{
            display: 'block',
            height: `var(--arrow-button-size, ${defaultArrowButtonSize}rem)`,
            margin: 'auto',
            stroke: theme.color.primary,
            strokeWidth: '2',
            width: 'auto'
          }}
        />
      </GenericButton>
    </div>
  );
};

export default ArrowButton;
